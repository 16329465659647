<template>
  <div v-if="dialogFormShow">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'300px'"
      :center="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="close"
    >
      <div class="shenhe-table-box">
        <div>审核后 该月份所有排放源,减排项目和经营数据不能再审核</div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="text-align: center"
        v-if="!readonly"
      >
        <el-button
          type="cancel"
          size="small"
          :loading="posting"
          @click="close()"
          style="margin-right: 20px"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          :loading="posting"
          @click="handleOk()"
          style="margin-left: 20px"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { treearr } from '@/tool'
import base from '@/templates/add'

export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      titleTable: '审核',
      dialogFormVisible: false,
      dialogFormShow: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      formLabelWidth: '100px',
      showAlias: false,
      factorType: '1',
      formInline: {
        page: {
          total: 0,
          size: 10,
          currentPage: 1
        }
      }
    }
  },
  computed: {},
  watch: {},
  beforeCreate () { },
  created () {
    this.loading = false
  },
  beforeMount () { },
  mounted () { },
  activated () { },
  deactivated () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  methods: {
    async init () { },
    show (data) {
      this.dialogFormShow = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.dialogFormVisible = false
      this.$nextTick((_) => {
        this.dialogFormShow = false
      })
    },
    handleOk () {
      this.posting = true
      api.ledger.checkLedger({
        ledgerDate: this.$parent.formInline.year + '-' + this.$parent.formInline.month + '-01',
        customId: this.$store.state.user.customId,
        year: this.$parent.formInline.year
      }).then(res => {
        this.posting = false

        this.$parent.init()
        this.dialogFormVisible = false
        this.$nextTick((_) => {
          this.dialogFormShow = false
        })
      }).catch(e => {
        this.posting = false
      })
    },
    handleCancelOk () {
      this.posting = true
      api.ledger.cancelCheck({
        id: this.$parent.currentMonthId,
        ledgerDate: this.$parent.formInline.year + '-' + this.$parent.formInline.month + '-01',
        customId: this.$store.state.user.customId,
        year: this.$parent.formInline.year
      }).then(res => {
        this.posting = false

        this.$parent.init()
        this.dialogFormVisible = false
        this.$nextTick((_) => {
          this.dialogFormShow = false
        })
      }).catch(e => {
        this.posting = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.shenhe-table-box {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
