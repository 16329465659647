import * as tslib_1 from "tslib";
import api from '@/api';
import { Component, Mixins } from 'vue-property-decorator';
import Base from '@/templates/list';
import getConfig from '@/config';
import MyDialog from './dialog';
import MyDialog2 from './dialog2';
let Bill = class Bill extends Mixins(Base) {
    constructor() {
        super(...arguments);
        this.fileList = [];
        this.deptList = [];
        this.calendarList = [];
        this.ledgerEmissionList = [];
        this.tableDataParams = { id: '', typeName: '', countType: '' };
        this.tableData = [];
        this.tableData2Params = { id: '' };
        this.tableData2 = [];
        this.statistics = { unFillEmissionCount: 0, unFillProductCount: 0 };
        this.uploadUrl = getConfig().serverApi + '/upload/uploadFile';
        this.uploadHeaders = {
            'X-Auth-Token': this.$store.state.user.token
        };
        this.formInline = {
            year: '2022',
            month: '04',
            orgId: this.$store.state.user.orgId,
            principalId: this.$store.state.user.id,
            page: {
                size: 10,
                currentPage: 1
            }
        };
        this.factorType = '1';
        this.tabIndex = '0';
        this.isEnable = 0;
        this.loading = false;
        this.buttonloading = false;
        this.whiteTypeName = ['逸散排放'];
        this.deviceInfoForm = {};
    }
    // private count: number = this.$store.state.count;
    // @Prop(Boolean) private defaultArg: string | undefined;
    // Emit('delemit') private delEmitClick (event: MouseEvent) {}
    // @Ref('aButton') readonly ref!: HTMLButtonElement;
    // computed;
    get isShowTable() {
        if (!this.calendarList.length) {
            return false;
        }
        var aa = this.calendarList.find(ele => Number(ele.month) == Number(this.formInline.month));
        if (aa.id) {
            return false;
        }
        else {
            return true;
        }
    }
    get currentMonthId() {
        if (!this.calendarList.length) {
            return '';
        }
        var aa = this.calendarList.find(ele => Number(ele.month) == Number(this.formInline.month));
        if (aa.id) {
            return aa.id;
        }
        else {
            return '';
        }
    }
    created() {
        this.init();
    }
    mounted() {
    }
    init() {
        this.loading = true;
        this.tableData = [];
        this.tableData2 = [];
        api.ledger.queryCheckedLedgerList({
            ledgerDate: this.formInline.year + '-' + this.formInline.month + '-01',
            customId: this.$store.state.user.customId,
            year: this.formInline.year
        }).then(res => {
            var list = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(ele => ({ month: ele, id: '', isFinished: 0 }));
            var nowDate = new Date();
            list.forEach((ele, index) => {
                ele.isFinished = 1;
                // 历史记录不计算
                if (Number(this.formInline.year) > nowDate.getFullYear() || Number(this.formInline.year) == nowDate.getFullYear() && Number(ele.month) > nowDate.getMonth() + 1) {
                    ele.isFinished = 0;
                }
                res.data.forEach(ele2 => {
                    if (ele2.month === ele.month) {
                        list[index] = ele2;
                        ele.isFinished = 0;
                    }
                });
            });
            this.calendarList = list;
        });
        api.ledger.queryLedgerStatistics({
            ledgerDate: this.formInline.year + '-' + this.formInline.month + '-01',
            customId: this.$store.state.user.customId,
            year: this.formInline.year
        }).then(res => {
            this.statistics = res.data;
        });
        api.ledger.queryCustomLedgerEmissionList({
            ledgerDate: this.formInline.year + '-' + this.formInline.month + '-01',
            customId: this.$store.state.user.customId,
            year: this.formInline.year
        }).then(res => {
            this.tableData = res.data;
        });
        api.ledger.queryCustomProductionList({
            ledgerDate: this.formInline.year + '-' + this.formInline.month + '-01',
            customId: this.$store.state.user.customId,
            year: this.formInline.year
        }).then(res => {
            this.tableData2 = res.data;
            this.loading = false;
        });
    }
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        return {
            rowspan: 1,
            colspan: 1
        };
    }
    handleChangeMonth(item) {
        this.formInline.month = item.month;
        this.init();
    }
    handleChangeYear(ff) {
        if (ff == 'prev' && Number(this.formInline.year) > 2000) {
            this.formInline.year--;
        }
        else if (ff == 'next' && Number(this.formInline.year) < 2100) {
            this.formInline.year++;
        }
        this.init();
    }
    handleNodeClick(data) {
        console.log(data);
    }
    handleAduit(data) {
        if (this.statistics.unFillEmissionCount > 0 || this.statistics.unFillProductCount > 0) {
            this.$message.error('当月存在未记账排放源, 不允许审核结账');
            return;
        }
        this.$refs.myDialog.show();
    }
    handleRemind(row) {
        this.$confirm('你确定提醒吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            api.ledger.sendLedgerMail({
                name: row.name,
                type: 0
            }).then(res => {
                this.$message.success('发送成功');
            });
        });
    }
    handleCancelCheck(data) {
        this.$refs.myDialog2.show();
    }
    resetForm2() {
        this.tableData2 = [];
    }
    resetForm() {
        this.tableData = [];
    }
    handleRemove(file, fileList) {
        console.log(file, fileList);
    }
    handlePreview(file) {
        console.log(file);
        window.open(file.url, file.name, '_blank');
    }
};
Bill = tslib_1.__decorate([
    Component({
        components: {
            MyDialog,
            MyDialog2
        }
    })
], Bill);
export default Bill;
