<template>
  <div v-if="dialogFormShow">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'300px'"
      :center="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="close"
    >
      <div class="shenhe-table-box">
          <div>确定 取消审核吗</div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center" v-if="!readonly">
        <el-button type="cancel" size="small" :loading="posting" @click="close()" style="margin-right: 20px;">
          取消
        </el-button>
        <el-button type="primary" size="small" :loading="posting" @click="handleCancelOk()" style="margin-left: 20px;">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from './dialog'

export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      titleTable: '取消审核'
    }
  },
  computed: {},
  watch: {},
  beforeCreate () { },
  created () {
  },
  beforeMount () { },
  mounted () { },
  activated () { },
  deactivated () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  methods: {

  }
}
</script>
